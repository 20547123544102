.outdated{
	display:none;
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	text-align:center;
	// text-transform:uppercase;
	z-index:1500;
	background-color:rgba(0, 0, 0, .3)!important;
	color:#fff;

	* html &{
		position:absolute;
	}
	p{
		font-size:12px;
		line-height:12px;
		margin:0
	}
	&-wrapper{
		background-color:#f25648;
		width:640px;
		height:480px;
		max-width:100%;
		max-height:100%;
		position:absolute;
		z-index:1;
		top:0;
		bottom:0;
		left:0;
		right:0;
		margin:auto;
		padding:120px 10px 60px;
		overflow:auto;
	}
	&-heading{
		font-size:25px;
		line-height:25px;
		margin:30px 0 10px;
	}
	&-btn-update{
		display:block;
		position:relative;
		padding:10px 20px;
		margin:30px auto 0;
		width:230px;
		color:#fff!important;
		text-decoration:none;
		border:2px solid #fff;
		cursor:pointer;

		&:hover{
			color:#f25648!important;
			background-color:#fff;
		}
	}
	.last{
		position:absolute;
		top:10px;
		right:25px;
		width:20px;
		height:20px
	}
	&-btn-close{
		display:block;
		position:relative;
		width:100%;
		height:100%;
		text-decoration:none;
		color:#fff!important;
		font-size:36px;
		line-height:36px
	}
}
